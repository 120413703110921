import React from "react"
import { FaWhatsapp } from "react-icons/fa"

import PropTypes from "prop-types"
import { Container, Header, Link } from "./styles"

export default function Whatsapp({ status }) {
  return (
    <Container status={status}>
      <Header>Atendimento por WhatsApp!</Header>

      <div>
        <Link
          href="https://wa.me/5511998422797?text=Olá, gostaria de falar com um consultor"
          target="_blank"
          rel="noopener noreferrer"
          className="link"
          onClick={e => {
            window.gtag("event", "conversion", {
              send_to: "AW-606920003/x4W0CKKAk8oZEMO6s6EC",
              value: 1.0,
              currency: "BRL",
            })
          }}
        >
          <FaWhatsapp />
          Fale Agora com o Consultor
        </Link>
      </div>
    </Container>
  )
}

Whatsapp.propTypes = {
  status: PropTypes.bool.isRequired,
}

Whatsapp.propDefault = {
  status: false,
}
