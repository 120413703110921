import React from "react"
import { useState } from "react"
import emailjs from "emailjs-com"
import ReCAPTCHA from "react-google-recaptcha"

export default function Form() {
  const initialForm = {
    name: null,
    email: null,
    phone: null,
    message: null,
  }

  const [captchaVerified, setCaptchaVerified] = useState(false) // State para o reCAPTCHA

  const [formData, setFormData] = useState(initialForm)

  const [formResult, setFormResult] = useState({
    show: false,
    type: null,
    message: null,
  })

  const updateFormData = (label, value) =>
    setFormData({ ...formData, [label]: value })

  const onCaptchaChange = value => {
    if (value) {
      setCaptchaVerified(true) // Marca o reCAPTCHA como verificado
    } else {
      setCaptchaVerified(false) // Se o reCAPTCHA for inválido
    }
  }

  async function sendEmail(e) {
    e.preventDefault()
    if (!captchaVerified) {
      setFormResult({
        show: true,
        type: "danger",
        message: "Por favor, verifique o reCAPTCHA antes de enviar.",
      })
      return
    }
    await emailjs.init("user_Vl1HzumexURYrNx0saCNA")
    emailjs.send("service_y3caa18", "template_fwrmqis", formData).then(
      result => {
        setFormResult({
          show: true,
          type: "success",
          message: "Email enviado com sucesso",
        })
      },
      error => {
        setFormResult({
          show: true,
          type: "danger",
          message:
            "Ocorreu um erro ao enviar o email. Por favor, tente novamente.",
        })
      }
    )
  }

  const fields = [
    {
      type: "text",
      name: "name",
      label: "Nome",
      placeHolder: "Seu nome",
    },
    {
      type: "email",
      name: "email",
      label: "E-mail",
      placeHolder: "Seu e-mail",
    },
    {
      type: "tel",
      name: "phone",
      label: "Telefone",
      placeHolder: "Seu telefone ou whatsapp",
    },
    {
      type: "textarea",
      name: "message",
      label: "Mensagem",
      placeHolder: "Sua mensagem",
    },
  ]

  return (
    <form onSubmit={sendEmail} className="form">
      {fields.map(field => {
        const fieldWrapper = input => (
          <div className="field">
            <label htmlFor={field.name} className="label">
              {field.label}
            </label>
            <div className="control">{input}</div>
          </div>
        )

        if (field.type === "textarea") {
          return fieldWrapper(
            <textarea
              name={field.name}
              className="textarea"
              rows={5}
              cols={30}
              onChange={({ target }) =>
                updateFormData(field.name, target.value)
              }
              required
            >
              {formData[field.name]}
            </textarea>
          )
        }

        return fieldWrapper(
          <input
            type={field.type}
            name={field.name}
            className="input"
            placeholder={field.placeHolder}
            onChange={({ target }) => updateFormData(field.name, target.value)}
            value={formData[field.name]}
            required
          />
        )
      })}
      <div className="field">
        <ReCAPTCHA
          sitekey="6Lf2UEYqAAAAAPu0lFsyiqEbVv6ycc6ORHsxzuCW" // Coloque sua chave do site aqui
          onChange={onCaptchaChange}
          hl="pt"
        />
      </div>
      {formResult.show && (
        <div
          className={`notification is-${
            formResult.type === "error" ? "danger" : "success"
          }`}
        >
          <button
            class="delete"
            onClick={() =>
              setFormResult({ show: false, type: null, message: null })
            }
          ></button>
          {formResult.message}
        </div>
      )}

      <div className="field has-text-centered">
        <button type="submit" className="button">
          Enviar
        </button>
      </div>
    </form>
  )
}
